// see: https://css-tricks.com/scale-svg/
// option 4: Use the padding-bottom Hack on a Container
// <div class="u-scalingSvgContainer" style="padding-bottom: 92%;">
//   <svg class="u-scalingSvg" viewBox="0 0 60 55" >
.u-scalingSvgContainer {
  position: relative;
  height: 0;
  width: 100%;
  padding: 0;
}

.u-scalingSvg {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

// Option 5
// add this as class to the svg. Additional add padding-bottom style as inline style
// <svg class="u-scalingInlineSVG" viewBox="0 0 60 55" preserveAspectRatio="xMidYMin slice" style="padding-bottom: 92%;">
.u-scalingInlineSVG {
  box-sizing: content-box !important;
  width: 100%;
  height: 1px !important;
  overflow: visible !important;
}