@use "sass:math";
@use "../abstracts/colors";
@use "../abstracts/constants";
@use "../abstracts/functions";
@use "../abstracts/mixins";

.Company-werftLogo {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

.Company-structureLogo {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

.Company-row {

}

.Company-colLogo {
  @include mixins.respond-to-grid(5) {
    width: math.percentage(math.div(3, 10));
  }
}

.Company-colCopy {
  @include mixins.respond-to-grid(5) {
    width: math.percentage(math.div(7, 10));
  }
}
