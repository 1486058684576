@use "../abstracts/colors";
@use "../abstracts/mixins";
@use "../abstracts/constants";

.Base-ul {
  @include mixins.font-Default;
  @include mixins.font-size(18px);

  font-weight: constants.$fontweight-Default-light;
  list-style: none;
  padding: 0;
  margin-bottom: 1em;

  li {
    color: colors.$black;
    padding-left: 1.75em;
    margin-bottom: 0.5em;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      display: block;
      top: 0.25em;
      left: 0;
      width: 1.25em;
      height: 0.7em;
      background-color: colors.$turquois;
      transform: skewX(-23.25deg);
    }
  }
}

.Base-ul--inline {
  margin-left: 0;

  li {
    display: inline-block;
    margin-bottom: 1em;
    padding-left: 0;
    padding-right: 1em;

    &::before {
      content: "";
    }

    ul {
      margin-left: 0.5em;
      padding: 0.25em 0 0;

      li::before {
        content: "";
      }
    }
  }
}

.Base-ol {
  @include mixins.font-Default;
  @include mixins.font-size(18px);

  font-weight: constants.$fontweight-Default-light;
  counter-reset: section;
  padding: 0;
  margin-bottom: 1em;

  li {
    display: block;
    position: relative;
    padding-left: 2.5em;
    margin-bottom: 1em;
    color: colors.$black;

    &::before {
      display: inline-block;
      content: counters(section, "") "";
      counter-increment: section;
      position: absolute;
      left: 0;
      width: 2em;
      padding-right: 0.5em;
      text-align: right;
      font-weight: constants.$fontweight-Default-light;
    }
  }

  ol {
    counter-reset: section;
    margin-top: 0.5em;
    margin-bottom: 1em;
    font-size: inherit;

    li::before {
      content: counters(section, ".") " ";
      background-color: transparent;
      width: auto;
      height: auto;
      border-right-color: colors.$black-40;
    }

    ol {
      li {
        padding-left: 3em;
      }
    }
  }
}
