@use "../abstracts/colors";
@use "../abstracts/constants";
@use "../abstracts/mixins";

.FooterNavigation {
  text-align: right;
  align-items: flex-start;
  justify-content: flex-end;
  display: flex;
  flex-wrap: wrap;
  margin-left: - constants.$gutter-width * 2;
  margin-top: - constants.$gutter-width;
}

.FooterNavigation-item {
  flex-basis: auto;
  margin: constants.$gutter-width 0 0 constants.$gutter-width * 2;
  display: block;
}

.FooterNavigation-link {
  @include mixins.font-Default;
  @include mixins.font-size(16px);

  font-weight: constants.$fontweight-Default-light;
  color: colors.$turquois;
  transition: color 0.2s ease;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 0.25em;
  }
}
