@use "sass:math";
@use "../abstracts/colors";
@use "../abstracts/constants";
@use "../abstracts/functions";
@use "../abstracts/mixins";

.BusinessAnimation {
  //padding-top: constants.$header-height-desktop;
  height: calc(100vh - #{constants.$header-height-desktop});
}

.BusinessAnimation-animation {
  width: 100%;
  height: auto;
  max-width: 1100px;
  margin: 0 auto;
}
