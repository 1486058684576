@use "../abstracts/colors";
@use "../abstracts/functions";
@use "../abstracts/mixins";
@use "../abstracts/constants";

.Base-link {
  border: none;
  cursor: pointer;
  color: colors.$turquois;

  &.is-active,
  &:hover,
  .u-linkWrap:hover & {
    text-decoration: underline;
  }

  .Base-theme--dark & {
    color: colors.$white;
  }

  .Base-theme--turquois & {
    color: colors.$white;
  }
}

.Base-teaserIconLink--large {
  @include mixins.font-size(24px);
}
