@use "../abstracts/constants";
@use "../abstracts/functions";
@use "../abstracts/mixins";

.u-columns-2 {
  column-count: 2;  // max number of columns
  column-width: 600px;  // min width of column
  column-gap: constants.$gutter-width*2;
}

.u-columns-4 {
  column-count: 4;  // max number of columns
  column-width: 300px;  // min width of column
  column-gap: constants.$gutter-width*2;
}

.u-columns-avoidbreak {
  break-inside: avoid;
}
