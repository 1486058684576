@use "../abstracts/constants";
@use "../abstracts/functions";
@use "../abstracts/colors";
@use "../abstracts/mixins";

.SectionFooter {
  position: relative;
  z-index: functions.z(constants.$z-root, footer);
  background-color: colors.$darkblue;
}

.SectionFooter-logo-link {
  display: block;
  margin-bottom: 3em;
}
