@use "sass:math";
@use "../abstracts/mixins";
@use "../abstracts/constants";

.GridCol4Even-row {
  margin-top: - constants.$gutter-width*2;
}

.GridCol4Even-col {
  margin-top: constants.$gutter-width*2;

  @include mixins.respond-to-grid(4) {
    width: math.percentage(math.div(6, 12));
  }

  @include mixins.respond-to-grid(8) {
    width: math.percentage(math.div(3, 12));
  }
}
