@use "sass:math";
@use "../abstracts/colors";
@use "../abstracts/constants";
@use "../abstracts/functions";
@use "../abstracts/mixins";

.VisionMission {
  position: relative;
  background-color: colors.$blue;
}

.VisionMission-section {
  background-image: url("../Images/visionmission-bg.svg");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  height: calc(100vh - #{constants.$header-height-desktop});
}

.VisionMission-section--vision {
  background-color: colors.$turquois;
  position: relative;
  z-index: 0;
}

.VisionMission-section--mission {
  background-color: colors.$blue;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.VisionMission-copyContainer {

}

.VisionMission-copyBox {
  margin-left: 48px;
  margin-right: 48px;

  @include mixins.respond-to(xs) {
    margin-left: 64px;
    margin-right: 64px;
  }

  @include mixins.respond-to(sm) {
    margin-left: 128px;
    margin-right: 128px;
  }
}
