@use "../abstracts/colors";

.Base-hr {
  border-top: none;
  margin: 0;
  height: 1px;
  border-radius: 2px;
  width: 100%;
  background-color: colors.$black-50;
}

