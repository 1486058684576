@use "../abstracts/constants";
@use "../abstracts/mixins";

// see https://vuetifyjs.com/en/styles/spacing/#how-it-works
@for $i from 0 through 8 {

  .u-pa-#{$i} {
    padding: constants.$gutter-width*$i !important;
  }

  .u-pt-#{$i} {
    padding-top: constants.$gutter-width*$i !important;
  }

  .u-pb-#{$i} {
    padding-bottom: constants.$gutter-width*$i !important;
  }

  .u-pl-#{$i} {
    padding-left: constants.$gutter-width*$i !important;
  }

  .u-pr-#{$i} {
    padding-right: constants.$gutter-width*$i !important;
  }

  .u-px-#{$i} {
    padding-left: constants.$gutter-width*$i !important;
    padding-right: constants.$gutter-width*$i !important;
  }

  .u-py-#{$i} {
    padding-top: constants.$gutter-width*$i !important;
    padding-bottom: constants.$gutter-width*$i !important;
  }

  @if $i > 0 {

    .u-pa-r#{$i} {
      @include mixins.padding(constants.$gutter-width*$i !important);

    }

    .u-pt-r#{$i} {
      @include mixins.padding-top(constants.$gutter-width*$i !important);
    }

    .u-pb-r#{$i} {
      @include mixins.padding-bottom(constants.$gutter-width*$i !important);
    }

    .u-pl-r#{$i} {
      @include mixins.padding-left(constants.$gutter-width*$i !important);
    }

    .u-pr-r#{$i} {
      @include mixins.padding-right(constants.$gutter-width*$i !important);
    }

    .u-px-r#{$i} {
      @include mixins.padding-left(constants.$gutter-width*$i !important);
      @include mixins.padding-right(constants.$gutter-width*$i !important);
    }

    .u-py-r#{$i} {
      @include mixins.padding-top(constants.$gutter-width*$i !important);
      @include mixins.padding-bottom(constants.$gutter-width*$i !important);
    }

  }

}
