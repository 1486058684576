@use "../abstracts/colors";
@use "../abstracts/constants";
@use "../abstracts/mixins";

.Base-button {
  @include mixins.font-Default;
  @include mixins.font-size(18px);
  font-weight: constants.$fontweight-Default-bold;
  cursor: pointer;
  color: colors.$black;
  background-color: colors.$black-50;
  border-radius: 10px;
  padding: 0.7em 2em;
  display: inline-flex;
  transition: color 0.2s ease, background-color 0.2s ease;
  border: 1px solid colors.$black-50;
  align-self: baseline;
  justify-content: flex-start;
  line-height: 1.25;
  outline-offset: 1px !important;

  &:focus {
    border-color: colors.$white;
  }

  &:hover,
  &.is-active,
  &:active {
    background-color: colors.$white;
    border-color: colors.$black-60;
  }

  &:disabled {
    cursor: default;
    opacity: 0.25;
    pointer-events: none;
  }

  .u-textAlign--center & {
    margin-left: 0;
    margin-right: 0;
  }

  .u-textAlign--right & {
    margin-left: 0.5em;
    margin-right: 0;
  }
}


.Base-button--block {
  display: flex;
  width: 100%;
}

.Base-button-icon {
  /* autoprefixer: off */
  display: inline-flex;
  fill: currentColor;
  width: 1.25em;
  flex-shrink: 0;
  margin-right: 0.5em;
  margin-left: -0.75em;

  .Base-button:hover &,
  .Base-button.is-active &,
  .Base-button:active & {
    color: colors.$black;
  }
}

.Base-button-suffix {
  font-weight: constants.$fontweight-Default-regular;
  margin-left: auto;
  margin-right: 0;
  flex-shrink: 0;
  padding-left: 0.5em;
}

// ie11 hack for buggy inline-flex
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .Base-button-icon {
    display: inline-block;
  }
}

.Base-button-iconOnly {
  display: block;
  fill: currentColor;
  width: 1em;
}
