@use "sass:math";
@use "../abstracts/colors";
@use "../abstracts/constants";
@use "../abstracts/functions";
@use "../abstracts/mixins";

.Recruiting-colCopy {
  @include mixins.respond-to-grid(5) {
    margin-left: auto;
    max-width: functions.col-width(4, false);
    width: math.percentage(math.div(8, 12));
  }
}

.Recruiting-colCta {
  display: flex;
  justify-content: center;

  @include mixins.respond-to-grid(5) {
    width: math.percentage(math.div(4, 12));
    justify-content: flex-start;
  }
}

.Recruiting-cta {
  @include mixins.font-Default;
  @include mixins.font-size(24px);

  font-weight: constants.$fontweight-Default-bold;
  text-transform: uppercase;
  line-height: 1.5em;
  color: colors.$white;
  background-color: colors.$turquois;
  border-radius: 50%;
  width: 180px;
  height: 180px;
  text-align: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s ease;
  will-change: transform;
  letter-spacing: 0.01em;

  &::before {
    content: "";
    height: 0.7em;
    width: 1em;
  }

  &::after {
    content: "";
    position: relative;
    display: block;
    width: 1.25em;
    height: 0.7em;
    background-color: colors.$white;
    transform: skewX(-23.25deg);
  }

  &:hover {
    transform: scale(1.1);
  }
}
