//  example usage
//	animation: alphaIn 1s;
//  animation-timing-function: linear;
//  animation-fill-mode: forwards;
@keyframes alphaIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes alphaOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
