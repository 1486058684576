@use "sass:math";
@use "sass:map";
@use "constants";
@forward "src/Sass/vendors/rfs/scss" with (
  $rfs-factor: constants.$rfs-factor,
  $rfs-breakpoint: constants.$rfs-breakpoint
);


//
// Media queries mixin
//
// example use:
//
// .test {
//  @include respond-to(xl) {  }
//  @include respond-to(lg) {  }
//  @include respond-to(md) {  }
//  @include respond-to(sm) { }
//  @include respond-to(xs) {  }
//  @include respond-to(xxs) {  }
// }

// Grid Settings
$_breakpoints: (
  xxs: 320px,
  xs: 576px,
  sm: 768px,
  md: 960px,
  lg: 1248px,
  xl: 1504px
);

@mixin respond-to($size: md) {
  @media (min-width: map.get($_breakpoints, $size)) {
    @content;
  }
}

// calc(4vw + #{$gutter-width})
// 100 - 2*4vw = 92
// 1 = 140, 2 = 279, 3 = 418, 4 = 557, 5 = 696, 6 = 835, 7 = 974, 8 = 1114, 9 = 1253, 10 = 1392, 11 = 1531, 12 = 1670
@mixin respond-to-grid($cols) {

  $inner-width: math.div(100 - constants.$outer-pad*2, 100);

  @media (min-width: #{math.div(constants.$col-width*$cols, $inner-width)}px) {
    @content;
  }
}

@mixin clearfix() {
  &::after {
    content: " ";
    display: block;
    clear: both;
  }
}

@mixin visuallyUnhidden {
  clip: auto;
  clip-path: none;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
  white-space: inherit;
}

@mixin visuallyHidden() {

  // Hide only visually, but have it available for screenreaders: h5bp.com/v
  & {
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap; /* 1 */
  }

  // Extends the .visuallyhidden class to allow the element to be focusable when navigated to via the keyboard: h5bp.com/p
  &.focusable:active,
  &.focusable:focus {
    @include visuallyUnhidden;
  }
}

@mixin font-Default {
  font-family: 'Hind', Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
}
