.u-align-left {
  margin-left: 0;
  margin-right: auto;
}

.u-align-center {
  margin-left: auto;
  margin-right: auto;
}

.u-align-right {
  margin-left: auto;
  margin-right: 0;
}
