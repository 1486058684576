@use "../abstracts/colors";
@use "../abstracts/constants";
@use "../abstracts/functions";
@use "../abstracts/mixins";

.ScrollTop-link {
  display: block;
  width: 44px;
  height: 44px;
  pointer-events: none;
  position: fixed;
  z-index: 10;
  right: 24px;
  bottom: 24px;
  opacity: 0;
  transform: scale(0, 0);
  transition: opacity 0.2s ease, transform 0.2s ease;
  border-radius: 50%;
  background-color: colors.$white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px, rgba(0, 0, 0, 0.12) 0px 1px 18px;

  &:focus,
  .is-scrollTop & {
    opacity: 1;
    transform: scale(1, 1);
    pointer-events: auto;
    transition-delay: 0.25s;
  }

  &:focus {
    transition-delay: 0s;
  }

  &:hover {
    transition-delay: 0s;
  }

  @include mixins.respond-to(sm) {
    width: 64px;
    height: 64px;
    right: 40px;
    bottom: 40px;
  }
}

.ScrollTop-iconSection {
  position: absolute;
  z-index: 1;
  color: colors.$turquois;
  fill: currentColor;
  top: 25%;
  left: 25%;
  width: 50%;
  transform: scale(1,-1);
  transition: transform 0.2s ease;

  .ScrollTop-link:hover & {
    transform: scale(1,-1) translateY(4px);
  }
}
