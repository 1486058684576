@use "sass:math";
@use "../abstracts/mixins";
@use "../abstracts/constants";
@use "../abstracts/colors";

.ContentAccordion {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.ContentAccordion-item {
  overflow: hidden;
  transition: background-color 0.2s ease;
  // border-bottom: 1px solid $color-sh-blau-10;
  // background-color: $color-white;

  // &.is-open {
    // background-color: $color-white;
    // background: linear-gradient(to right, $color-ice 0%,$color-ice 6px, $color-ice-light 6px,$color-ice-light 100%);
  // }

}

.ContentAccordion-title {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  outline-offset: -2px !important;
  hyphens: auto;
  background-color: colors.$white;
  border-bottom: 1px solid colors.$silver;
  padding-top: 1.25em;
  transition: background-color 0.2s ease;

  .is-open & {
    background-color: colors.$white;
  }

  &:hover {
    background-color: colors.$white;
  }
}

.ContentAccordion-icon {
  @include mixins.rfs(36px, width);
  @include mixins.rfs(36px, height);

  line-height: 1;
  color: colors.$turquois;
  fill: currentColor;
  flex-shrink: 0;
  margin-right: 0.5em;
  margin-left: 0.5em;
  transition: transform 0.2s ease, color 0.2s ease;

  .is-open & {
    transform: scaleY(-1);
    color: colors.$turquois;
  }

  .ContentAccordion-title:hover & {
    color: colors.$turquois;
  }
}

.ContentAccordion-label {
  @include mixins.font-Default;
  @include mixins.font-size(36px);

  font-weight: constants.$fontweight-Default-bold;
  line-height: 1.25em;
  color: colors.$turquois;
  transition: transform 0.2s ease, color 0.2s ease;

  // .ContentAccordion-title:hover & {
  //  color: colors.$turquois;
  // }
  //
  // .is-open & {
  //  color: colors.$turquois;
  // }
}

.ContentAccordion-panel {
  display: none;
  padding: constants.$gutter-width*2 0;
}
