@use "../abstracts/colors";
@use "../abstracts/constants";
@use "../abstracts/mixins";

.Base-h1 {
  @include mixins.font-Default;
  @include mixins.font-size(60px);

  font-weight: constants.$fontweight-Default-light;
  line-height: 1;
  hyphens: auto;
  display: block;
  color: colors.$turquois;
  letter-spacing: 0.01em;
}

.Base-h2 {
  @include mixins.font-Default;
  @include mixins.font-size(38px);

  font-weight: constants.$fontweight-Default-bold;
  hyphens: auto;
  line-height: 1.15em;
  color: colors.$turquois;
  text-transform: uppercase;
  letter-spacing: 0.01em;
}

.Base-h3 {
  @include mixins.font-Default;
  @include mixins.font-size(40px);

  font-weight: constants.$fontweight-Default-bold;
  line-height: 1.25em;
  color: colors.$turquois;
  text-transform: uppercase;
  letter-spacing: 0.01em;

  .Base-theme--turquois & {
    color: colors.$white;
  }
}

.Base-h4 {
  @include mixins.font-Default;
  @include mixins.font-size(48px);

  font-weight: constants.$fontweight-Default-bold;
  line-height: 1.25em;
  color: colors.$turquois;
  letter-spacing: 0.01em;

  .Base-theme--turquois & {
    color: colors.$white;
  }
}

.Base-h4--small {
  @include mixins.font-size(38px);
}

.Base-h5 {
  @include mixins.font-Default;
  @include mixins.font-size(24px);

  font-weight: constants.$fontweight-Default-bold;
  line-height: 1.25em;
  color: colors.$turquois;
  letter-spacing: 0.01em;

  .Base-theme--turquois & {
    color: colors.$white;
  }
}

.Base-h6 {
  @include mixins.font-Default;
  @include mixins.font-size(18px);

  font-weight: constants.$fontweight-Default-bold;
  line-height: 1.25em;
  color: colors.$turquois;
  letter-spacing: 0.01em;

  .Base-theme--turquois & {
    color: colors.$white;
  }
}
