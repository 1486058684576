@use "../abstracts/constants";
@use "../abstracts/mixins";

// see https://vuetifyjs.com/en/styles/spacing/#how-it-works
@for $i from 0 through 8 {

  .u-ma-#{$i} {
    margin: constants.$gutter-width*$i !important;
  }

  .u-mt-#{$i} {
    margin-top: constants.$gutter-width*$i !important;
  }

  .u-mb-#{$i} {
    margin-bottom: constants.$gutter-width*$i !important;
  }

  .u-ml-#{$i} {
    margin-left: constants.$gutter-width*$i !important;
  }

  .u-mr-#{$i} {
    margin-right: constants.$gutter-width*$i !important;
  }

  .u-mx-#{$i} {
    margin-left: constants.$gutter-width*$i !important;
    margin-right: constants.$gutter-width*$i !important;
  }

  .u-my-#{$i} {
    margin-top: constants.$gutter-width*$i !important;
    margin-bottom: constants.$gutter-width*$i !important;
  }

  @if $i > 0 {

    .u-ma-n#{$i} {
      margin: constants.$gutter-width*-$i !important;
    }

    .u-mt-n#{$i} {
      margin-top: constants.$gutter-width*-$i !important;
    }

    .u-mb-n#{$i} {
      margin-bottom: constants.$gutter-width*-$i !important;
    }

    .u-ml-n#{$i} {
      margin-left: constants.$gutter-width*-$i !important;
    }

    .u-mr-n#{$i} {
      margin-right: constants.$gutter-width*-$i !important;
    }

    .u-mx-n#{$i} {
      margin-left: constants.$gutter-width*-$i !important;
      margin-right: constants.$gutter-width*-$i !important;
    }

    .u-my-n#{$i} {
      margin-top: constants.$gutter-width*-$i !important;
      margin-bottom: constants.$gutter-width*-$i !important;
    }


    .u-ma-r#{$i} {
      @include mixins.margin(constants.$gutter-width*$i !important);
    }

    .u-mt-r#{$i} {
      @include mixins.margin-top(constants.$gutter-width*$i !important);
    }

    .u-mb-r#{$i} {
      @include mixins.margin-bottom(constants.$gutter-width*$i !important);
    }

    .u-ml-r#{$i} {
      @include mixins.margin-left(constants.$gutter-width*$i !important);
    }

    .u-mr-r#{$i} {
      @include mixins.margin-right(constants.$gutter-width*$i !important);
    }

    .u-mx-r#{$i} {
      @include mixins.margin-left(constants.$gutter-width*$i !important);
      @include mixins.margin-right(constants.$gutter-width*$i !important);
    }

    .u-my-r#{$i} {
      @include mixins.margin-top(constants.$gutter-width*$i !important);
      @include mixins.margin-bottom(constants.$gutter-width*$i !important);
    }

  }

}
