@use "../abstracts/constants";
@use "../abstracts/functions";

/*

limitWidth always inside the container, not in same div

<div class="u-container [colorstyle here]">

  <p>Text can be placed here</p>

  <div class="u-limitWidth-[limitSize] u-align-center">
    <p>Text can be placed here</p>

    <div class="u-row">
      <div class="[colwidth]">
        <p>Text can be placed here</p>
      </div>
    </div>

  </div>

  </div>
</div>

 */

// common styling applied to any column
@mixin grid-col {
  padding-left: constants.$gutter-width;
  padding-right: constants.$gutter-width;
}

// common styling applied to any row
@mixin grid-row {
  margin-left: -(constants.$gutter-width);
  margin-right: -(constants.$gutter-width);

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  flex: 1;
}

.u-container {
  padding-right: constants.$gutter-width*2;
  padding-left: constants.$gutter-width*2;
  width: 100%;
}

.u-row {
  @include grid-row;
}

.u-col {
  @include grid-col;
  width: 100%;
}

// 1 col = 128
// .u-limitWidth-1 = 182 - 32px = 96
// .u-limitWidth-12 = 12*128 = 1536 - 2*gutterwidth = 1504
// .u-limitWidth-20 = 20*128 = 2560 - 2*gutterwidth = 2528
@for $i from 1 through 20 {
  .u-limitWidth-#{$i} {
    width: 100%;
    max-width: functions.col-width($i, false);
  }
}
