@use "../abstracts/colors";
@use "../abstracts/constants";
@use "../abstracts/mixins";

.Base-p {
  @include mixins.font-Default;
  @include mixins.font-size(18px);

  font-weight: constants.$fontweight-Default-light;
  color: colors.$black;

  .Base-theme--dark & {
    color: colors.$white;
  }

  .Base-theme--turquois & {
    color: colors.$white;
  }
}

.Base-p--small {
  @include mixins.font-size(16px);

  color: colors.$black-70;
}

.Base-p--large {
  @include mixins.font-size(26px);

  color: colors.$black-60;
}

.Base-p--block {
  display: block;
}
