@use "../abstracts/colors";
@use "../abstracts/constants";
@use "../abstracts/functions";
@use "../abstracts/mixins";
@use "sass:math";

.SectionHeader {
  position: fixed;
  z-index: functions.z(constants.$z-root, header);
  background-color: colors.$white;
  width: 100%;
  height: constants.$header-height-desktop;
  box-shadow: 0 2px 10px 0 rgba(colors.$black, 0.25);
  display: flex;
  justify-content: flex-start;
  top: 0;
  left: 0;
  align-items: center;

  @include mixins.respond-to-grid(5) {
    justify-content: center;
  }
}

.SectionHeader-logo-link {
  display: block;
  width: 180px;

  @include mixins.respond-to-grid(5) {
    margin-left: 30px;
  }
}

.SectionHeader-logo {
  display: block;
}

.SectionHeader-menuButton {
  position: absolute;
  right: 32px;
  display: flex;
  justify-items: center;
  align-items: center;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  column-gap: 0.25em;
  color: colors.$blue;
  transition: color 0.2s ease;

  &:hover {
    color: colors.$darkblue;
  }
}

.SectionHeader-menuLabel {
  @include mixins.font-Default;
  @include mixins.font-size(16px);

  font-weight: constants.$fontweight-Default-light;
  line-height: 1;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  margin-top: 0.2em;
}

.SectionHeader-menuIcon {
  display: block;
  width: 32px;
  height: 32px;
  fill: currentcolor;
}

.SectionHeader-closeButton {
  position: absolute;
  right: 32px;
  top: 0;
  height: constants.$header-height-desktop;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: colors.$turquois;
  transition: color 0.2s ease;

  &:hover {
    color: colors.$white;
  }
}

.SectionHeader-closeIcon {
  display: block;
  width: 32px;
  height: 32px;
  fill: currentcolor;
}

.SectionHeader-menuPanel {
  position: absolute;
  top: 0;
  right: 0;
  background-color: colors.$blue;
  padding-top: constants.$header-height-desktop;
  padding-left: 6vw;
  padding-right: 6vw;
  visibility: hidden;
  display: block;
  opacity: 0;
  transition: opacity 0.2s ease, transform 0.2s ease, visibility 0.2s ease;
  border-top: 1px solid colors.$black-20;
  height: 100vh;

  &.is-open {
    transition: opacity 0.2s ease, transform 0.2s ease;
    visibility: visible;
    opacity: 1;
    box-shadow: 0 2px 10px 0 rgba(colors.$black, 0.25);
    overflow-y: auto;
  }

}

.SectionHeader-menu-level1 {
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 2em;
  }
}

.SectionHeader-menuItem-level1 {

}

.SectionHeader-menuLink-level1 {
  @include mixins.font-Default;
  @include mixins.font-size(24px);

  font-weight: constants.$fontweight-Default-light;
  line-height: 1;
  color: colors.$turquois;
  letter-spacing: 0.01em;
  text-transform: uppercase;

  &:hover {
    text-decoration: underline;
  }
}

.SectionHeader-menu-level2 {
  display: flex;
  flex-direction: column;
  margin-top: 2em;
  margin-left: 1em;

  & > * + * {
    margin-top: 2em;
  }
}

.SectionHeader-menuItem-level2 {
}

.SectionHeader-menuLink-level2 {
  @include mixins.font-Default;
  @include mixins.font-size(24px);

  font-weight: constants.$fontweight-Default-light;
  line-height: 1;
  color: colors.$turquois;
  letter-spacing: 0.01em;
  text-transform: uppercase;

  &:hover {
    text-decoration: underline;
  }
}
