@use "sass:math";
@use "../abstracts/colors";
@use "../abstracts/constants";
@use "../abstracts/functions";
@use "../abstracts/mixins";

.Challenges {
  background-color: colors.$blue;
}

.Challenges-headline {
  @include mixins.font-Default;
  @include mixins.font-size(38px);

  font-weight: constants.$fontweight-Default-light;
  hyphens: auto;
  line-height: 1em;
  color: colors.$turquois;
  position: relative;
  text-transform: uppercase;

  &::before {
    content: "";
    position: relative;
    display: inline-block;
    top: 0;
    left: 0;
    width: 1.25em;
    height: 0.7em;
    background-color: colors.$turquois;
    transform: skewX(-23.25deg);
    margin-right: 0.5em;
  }
}

.Challenges-row {
  &.Challenges-row-reversedOrder {
    justify-content: flex-end;
  }
}

.Challenges-colPictogram {
  will-change: transform;

  @include mixins.respond-to-grid(5) {
    width: math.percentage(math.div(5, 12));
  }
}

.Challenges-colCopy {
  margin-top: 2em;
  margin-bottom: 4em;

  @include mixins.respond-to-grid(5) {
    width: math.percentage(math.div(5, 12));
    margin-top: 4.5em;
    margin-bottom: 1em;

    .Challenges-row-reversedOrder & {
      order: -1;
    }
  }
}

.Challenges-pictogram {
  display: block;
  margin: 0 auto;
}
