@use "../abstracts/colors";
@use "../abstracts/mixins";
@use "../abstracts/constants";

.Base-figure {
  display: table;
  margin-bottom: constants.$gutter-width;
}

.Base-figure-image {
  display: block;
  width: 100%;
  vertical-align: bottom;
  //margin-bottom: $gutter-width;
}

.Base-figcaption {
  @include mixins.font-Default;
  @include mixins.font-size(16px);

  display: table-caption;
  caption-side: bottom;

  margin-top: constants.$gutter-width;

  color: colors.$black-70;
}
