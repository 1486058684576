@use "sass:math";
@use "../abstracts/mixins";
@use "../abstracts/constants";

.GridCol2Even-row {
  margin-top: - constants.$gutter-width * 2;
}

.GridCol2Even-col {
  margin-top: constants.$gutter-width * 2;

  @include mixins.respond-to-grid(5) {
    width: math.percentage(math.div(6, 12));
  }
}

.GridCol2Even-col--offsetLeft {
  @include mixins.respond-to-grid(7) {
    width: math.percentage(math.div(5, 12));
    margin-left: auto;
  }
}

.GridCol2Even-header--offsetLeft {
  @include mixins.respond-to-grid(7) {
    margin-left: math.percentage(math.div(1, 12));
  }
}

.GridCol2Even-col--offsetRight {
  @include mixins.respond-to-grid(7) {
    width: math.percentage(math.div(5, 12));
    margin-right: math.percentage(math.div(1, 12));
  }
}
