@use "sass:math";
@use "../abstracts/colors";
@use "../abstracts/constants";
@use "../abstracts/functions";
@use "../abstracts/mixins";

.Contact-row {
  align-items: center;
}

.Contact-colImage {
  margin-bottom: 1em;

  @include mixins.respond-to-grid(5) {
    margin-bottom: 0;
    width: math.percentage(math.div(4, 12));
  }
}

.Contact-colCopy {
  @include mixins.respond-to-grid(5) {
    width: math.percentage(math.div(8, 12));
  }
}

.Contact-image {
  border-radius: 50%;
  display: block;
  overflow: hidden;
  margin: 0 auto;

  @include mixins.respond-to-grid(5) {
    margin: 0;
  }
}
