@use "../abstracts/colors";
@use "../abstracts/constants";
@use "../abstracts/functions";
@use "../abstracts/mixins";

.Intro {
  width: 100%;
  height: calc(100vh - #{constants.$header-height-desktop});
  background-color: colors.$turquois;
  position: relative;
  overflow: hidden;
}

.Intro-videoloop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.Intro-logoClaimContainer {
  position: absolute;
  z-index: 1;
  top: 30%;

  @include mixins.respond-to(sm) {
    top: 20%;
  }
}

.Intro-logo {
  display: block;
  height: 20px;
  width: auto;
  flex-shrink: 0;
  margin-right: 8px;
  filter: drop-shadow(2px 2px 6px rgb(0 0 0 / 0.2));

  @include mixins.respond-to(sm) {
    margin-right: 28px;
    height: 80px;
  }
}

.Intro-logoClaimBox {
  display: flex;
  align-items: flex-start;
}

.Intro-claimAnimationBox {
  flex-shrink: 0;
  overflow: hidden;
  height: 20px;

  @include mixins.respond-to(sm) {
    height: 80px;
  }
}

.Intro-claim {
  display: block;
  height: 20px;
  width: auto;

  @include mixins.respond-to(sm) {
    height: 80px;
  }
}

.Intro-scrollDown-link {
  display: block;
  width: 64px;
  height: 64px;
  position: absolute;
  bottom: 0;
  left: calc(50% - 32px);
  color: colors.$turquois;
  fill: currentColor;
  transition: transform 0.2s ease;

  &:hover {
    transform: translateY(8px);
  }
}

.Intro-claimAnimation {
  will-change: transform;
  animation: introclaim-small 8s linear infinite;

  @include mixins.respond-to(sm) {
    animation-name: introclaim-large;
  }
}

@keyframes introclaim-small {
  0% {
    transform: translateY(0);
  }

  20% {
    transform: translateY(0);
  }

  22% {
    transform: translateY(-20px);
  }

  42% {
    transform: translateY(-20px);
  }

  44% {
    transform: translateY(-40px);
  }

  64% {
    transform: translateY(-40px);
  }

  66% {
    transform: translateY(-60px);
  }

  98% {
    transform: translateY(-60px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes introclaim-large {
  0% {
    transform: translateY(0);
  }

  20% {
    transform: translateY(0);
  }

  22% {
    transform: translateY(-80px);
  }

  42% {
    transform: translateY(-80px);
  }

  44% {
    transform: translateY(-160px);
  }

  64% {
    transform: translateY(-160px);
  }

  66% {
    transform: translateY(-240px);
  }

  98% {
    transform: translateY(-240px);
  }

  100% {
    transform: translateY(0);
  }
}


.Intro-claim--care {
  will-change: transform;
  animation: introclaim-care-small 8s ease infinite;

  @include mixins.respond-to(sm) {
    animation-name: introclaim-care-large;
  }
}

@keyframes introclaim-care-small {
  0% {
    transform: translateX(-32px);
  }
  70% {
    transform: translateX(-32px);
  }
  74% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(0px);
  }
}


@keyframes introclaim-care-large {
  0% {
    transform: translateX(-128px);
  }
  70% {
    transform: translateX(-128px);
  }
  74% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(0px);
  }
}
