@use "../abstracts/colors";

.u-background--white {
  background-color: colors.$white;
}

.u-background--black {
  background-color: colors.$black;
}

.u-background--turquoise {
  background-color: colors.$turquois;
}

.u-background--blue {
  background-color: colors.$blue;
}
