@use "sass:math";
@use "../abstracts/colors";
@use "../abstracts/constants";
@use "../abstracts/functions";
@use "../abstracts/mixins";

.Worldmap {
  position: relative;
  background-color: colors.$silver-20;
}

.Worldmap-image {
  display: block;
  margin: 0 auto;
}

.Worldmap-containerCopy {
  @include mixins.respond-to(sm) {
    position: absolute;
  }
}
