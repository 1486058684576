.u-textAlign--left {
  text-align: left !important;
}

.u-textAlign--right {
  text-align: right !important;
}

.u-textAlign--center {
  text-align: center !important;
}
