@use "sass:math";
@use "../abstracts/mixins";
@use "../abstracts/constants";

.GridCol3Even-row {
  margin-top: - constants.$gutter-width*2;
}

.GridCol3Even-col {
  margin-top: constants.$gutter-width*2;

  @include mixins.respond-to-grid(5) {
    width: math.percentage(math.div(6, 12));
  }

  @include mixins.respond-to-grid(7) {
    width: math.percentage(math.div(4, 12));
  }
}

.GridCol3Even-colFullFill {
  margin-top: constants.$gutter-width*2;

  @include mixins.respond-to-grid(5) {
    width: math.percentage(math.div(12, 12));
  }

  @include mixins.respond-to-grid(7) {
    width: math.percentage(math.div(4, 12));
  }
}
