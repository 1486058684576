@mixin resetButton {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;

  &:disabled {
    cursor: default;
    pointer-events: none;
  }
}

.u-resetButton {
  @include resetButton;
}
