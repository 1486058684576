@use "sass:math";
@use "../abstracts/colors";
@use "../abstracts/constants";
@use "../abstracts/functions";
@use "../abstracts/mixins";

.SectionMain {
  position: relative;
  z-index: functions.z(constants.$z-root, main);
  background-color: colors.$white;
  margin-top: constants.$header-height-desktop;
}
