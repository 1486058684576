@use "../abstracts/constants";
@use "../abstracts/mixins";

@for $i from 1 through 8 {

  // owl gutter stack
  .u-stack-#{$i} {

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    & > * {
      flex-basis: auto;
    }

    & > * + * {
      margin-top: constants.$gutter-width*$i;
    }
  }

  // RFS owl gutter rfs stack
  .u-stack-r#{$i} {

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    & > * {
      flex-basis: auto;
    }

    & > * + * {
      @include mixins.margin-top(constants.$gutter-width*$i);
    }
  }
}

// https://thoughtbot.com/blog/utilities-i-have-known-and-loved
@for $i from 1 through 4 {
  .u-stack-flex-#{$i} {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    margin-left: - constants.$gutter-width*$i;
    margin-top: - constants.$gutter-width*$i;

    & > * {
      flex-basis: auto;
      margin: constants.$gutter-width*$i 0 0 constants.$gutter-width*$i;
    }
  }
}

.u-stack-flex--alignItemsCenter {
  align-items: center;
}

// owl selector stacking
@for $i from 1 through 4 {

  .u-stack-text-#{$i} {

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    & > * {
      flex-basis: auto;
    }

    & > * + * {
      margin-top: #{$i}em;
    }
  }
}

.u-stack-text-xxs,
.u-stack-text-xs,
.u-stack-text-sm,
.u-stack-text-md,
.u-stack-text-lg,
.u-stack-text-xl,
.u-stack-text-xxl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  & > * {
    flex-basis: auto;
  }
}

.u-stack-text-xxs > * + * {
  margin-top: 0.25em;
}

.u-stack-text-xs > * + * {
  margin-top: 0.5em;
}

.u-stack-text-sm > * + * {
  margin-top: 0.75em;
}

.u-stack-text-md > * + * {
  margin-top: 1em;
}

.u-stack-text-lg > * + * {
  margin-top: 1.5em;
}

.u-stack-text-xl > * + * {
  margin-top: 2em;
}

.u-stack-text-xxl > * + * {
  margin-top: 2.5em;
}
