@use "sass:math";
@use "../abstracts/colors";
@use "../abstracts/constants";
@use "../abstracts/functions";
@use "../abstracts/mixins";

.Business-container {
  @include mixins.respond-to(sm) {
    min-height: calc(100vh - #{constants.$header-height-desktop});
    background: linear-gradient(113.25deg, colors.$blue 0% 42%, colors.$white calc(42% + 1px) 100%);
    display: flex;
    align-items: center;
    position: relative;

    &.Business-container--reversedOrder {
      background: linear-gradient(113.25deg, colors.$white 0% 58%, colors.$blue calc(58% + 1px) 100%);
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
  }
}

.Business-row {
  align-items: center;
}

.Business-colPictogram {
  background-color: colors.$blue;
  padding-top: 1em;
  padding-bottom: 1em;
  margin-bottom: 1em;

  @include mixins.respond-to(sm) {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    background-color: transparent;
    width: math.percentage(math.div(4, 12));

    .Business-container--reversedOrder & {
      margin-left: auto;
    }
  }
}

.Business-colCopy {
  @include mixins.respond-to(sm) {
    width: math.percentage(math.div(6, 12));
    margin-left: auto;
    margin-top: 4em;

    .Business-container--reversedOrder & {
      order: -1;
      margin-left: 0;
      margin-top: 0;
      margin-bottom: 4em;
    }
  }
}

.Business-pictogram {
  display: block;
  margin: 0 auto;
}

.Business-image {
  width: 100%;
  display: block;
  max-width: none;
  padding: 0 constants.$gutter-width;

  @include mixins.respond-to(sm) {
    padding: 0;
  }
}
