@use "../abstracts/colors";
@use "../abstracts/constants";
@use "../abstracts/functions";
@use "../abstracts/mixins";
@use "sass:math";

.IntroCopy-socialIconLink {
  color: colors.$white;
  fill: currentcolor;
  display: flex;
  column-gap: 0.5em;
  align-items: center;
}

.IntroCopy-socialIconLink-icon {
  width: 64px;
  display: block;
}

.IntroCopy-socialIconLink-label {
  @include mixins.font-Default;
  @include mixins.font-size(20px);

  font-weight: constants.$fontweight-Default-regular;
  line-height: 1;
  letter-spacing: 0.01em;
  text-transform: uppercase;

  .IntroCopy-socialIconLink:hover & {
    text-decoration: underline;
  }
}
